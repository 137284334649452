<template>
  <div class="side-panel" :class="{ showpanel: panelIsOpen }">
    <div class="panel">
      <p class="font-black">{{ 'Demandez une soumission' }}</p>
      <br />
      <form action="/success" name="soumissions" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="soumissions" />

        <input type="text" placeholder="* Prénom et nom" name="nom" required />
        <input type="email" placeholder="* Courriel" name="courriel" required />

        <input type="text" placeholder="Téléphone" name="telephone" />
        <input type="text" placeholder="Adresse" name="adresse" />
        <input type="text" placeholder="Superficie du terrain" name="superficie" />
        <p class="notice">
          * Le prix sera ajusté en fonction de la superficie réelle du terrain.
        </p>
        <input type="hidden" name="customSource" value="" />

        <textarea placeholder="Message" name="message"></textarea>

        <div class="ctas"><button class="site-btn" type="submit" >Demandez une soumission</button></div>
      </form>
      
      <img src="@/assets/img/close.svg" class="close" alt="close" @click.prevent="togglePanel(!panelIsOpen)" />
    </div>
    <div class="overlay" @click.prevent="togglePanel(!panelIsOpen)"></div>
  </div>
</template>

<script>
export default {
  name: 'SideForm',

  props: {
    panelIsOpen: {
      type: Boolean,
      default: false,
    },
    togglePanel: {
      type: Function,
      required: true,
    },
  },

  watch: {
    $route: {
      handler($route) {
        // We have a tracker query in URL
        if ($route && $route.query && $route.query.customSource) {
          setTimeout(() => {
            // Set it
            document.querySelector(
              '[name="customSource"]'
            ).value = `${$route.query.customSource}`
            // And register tracker
            localStorage.setItem('customSourceValue', `${$route.query.customSource}`)
            localStorage.setItem('customSourceDate', `${new Date().toISOString().slice(0, 10)}`)
          }, 1755)
        } else if($route && !$route.query.customSource) {
          // We do not have any tracker in URL
          // Do we have a registerd tracker on user ?
          // Is this tracker smaller or egual to than 14 days old ?
          const date1 = new Date(localStorage.getItem('customSourceDate'))
          const date2 = new Date()
          const diffInTime = date2.getTime() - date1.getTime()
          const diffInDays = diffInTime / (1000 * 3600 * 24)
          if (
            localStorage.getItem('customSourceValue') && 
            localStorage.getItem('customSourceDate') &&
            diffInDays <= 14
          ) {
            setTimeout(() => {
              // Set it
              document.querySelector(
                '[name="customSource"]'
              ).value = `${localStorage.getItem('customSourceValue')}`
            }, 1755)
          }
        }
      },
      immediate: true
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.side-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 0.2s ease;

  &.showpanel {
    z-index: 20000;

    .overlay {
      opacity: 0.5;
    }

    .panel {
      right: 0;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $colorAccent2;
  opacity: 0;
  z-index: 20000;
  transition: all 0.2s ease;
}

.panel {
  position: fixed;
  top: 0;
  right: -35vw;
  height: 100vh;
  width: 35vw;
  background-color: $colorAccent3;
  color: $dark;
  z-index: 30000;
  padding: 4vw 3vw;
  transition: all 0.2s ease;

  .close {
    position: absolute;
    top: 2vw;
    right: 1.5vw;
    width: 15px;
    cursor: pointer;
  }

  .font-black {
    font-family: $fontTitle;
    color: $dark;
    font-size: 20px;
    margin-bottom: 5px;
    letter-spacing: 1px;
    font-style: normal;
    font-weight: 500;
  }

  .regular-text {
    margin-bottom: 10px;
    span {
      color: $dark;
    }
  }

  form {
    input[type='text'],
    input[type='email'] {
      width: 100%;
      height: 50px;
      border: 0;
      background-color: rgba($dark, 0.1);
      padding: 10px;
      font-family: $fontText;
      font-weight: 500;
      font-style: normal;
      font-size: 14px;
      color: $dark;
      margin-bottom: 6px;
      border-radius: 3px;
      -webkit-appearance: none;

      &::placeholder {
        color: $colorAccent2;
      }
    }
    textarea {
      width: 100%;
      height: 150px;
      max-width: 100%;
      min-width: 100%;
      max-height: 150px;
      min-height: 150px;
      border: 0;
      background-color: rgba($dark, 0.1);
      padding: 10px;
      font-family: $fontText;
      font-weight: 500;
      font-style: normal;
      font-size: 14px;
      color: $dark;
      margin-bottom: 6px;
      border-radius: 3px;
      -webkit-appearance: none;

      &::placeholder {
        color: $colorAccent2;
      }
    }
  }

  @media (max-width: 1100px) {
    width: 40vw;
    right: -40vw;
  }
  @media (max-width: 900px) {
    width: 50vw;
    right: -50vw;
  }
  @media (max-width: 650px) {
    width: 65vw;
    right: -65vw;
  }
  @media (max-width: 500px) {
    width: 100%;
    right: -100%;
    padding: 10vw 8vw;

    .close {
      top: 5vw;
      right: 5vw;
    }

    form {
      textarea {
        height: 100px;
        max-height: 100px;
        min-height: 100px;
      }
    }
  }
}

.ctas {
  text-align: center;
  padding-top: 15px;
}

.notice {
  font-size: 12px;
  font-style: italic;
  text-align: center;
  padding: 0;
  padding-top: 8px;
  padding-bottom: 13px;
  margin: 0;
  line-height: 1;
}
</style>
