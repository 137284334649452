<template>
  <svg class="particule" height="340" viewBox="0 0 271 430" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.1">
      <path
        d="M269.12 423.175C233.9 397.097 211.317 361.608 192.227 323.162C179.053 296.545 167.223 269.122 153.782 242.774C135.5 206.479 123.67 168.57 117.218 129.049C113.993 109.961 112.648 90.3341 110.228 70.9767C109.959 69.0944 108.346 67.2122 107.27 65.0625C105.119 66.6753 103.238 67.4817 102.43 68.827C97.0526 77.1624 96.7831 86.3022 98.9348 95.7114C101.893 108.885 104.312 122.328 107.538 135.502C117.486 174.217 126.358 212.932 145.177 248.689C159.963 276.381 173.676 304.879 187.117 333.108C202.443 364.833 223.413 392.526 247.878 417.259C252.718 422.099 258.363 425.862 264.01 429.357C265.623 430.432 268.581 429.087 271 428.82C270.463 427.207 270.463 424.249 269.118 423.173L269.12 423.175Z"
        fill="#51504D"
      />
      <path
        d="M155.127 94.9048C148.137 104.853 137.382 114.532 146.791 129.049C147.867 130.662 146.254 133.889 145.715 136.039C144.103 141.147 139.801 150.288 140.608 150.825C148.674 155.666 151.362 147.061 155.933 142.759C159.159 139.801 161.58 136.306 164.536 133.35C179.055 118.831 193.572 104.584 207.822 90.0645C211.047 86.5694 216.694 82.8049 211.047 77.6971C209.971 76.6212 203.788 79.5793 201.099 81.9985C189.001 92.7531 177.44 104.045 165.61 114.799C163.997 116.142 161.308 116.142 159.157 116.681C159.157 114.262 158.081 111.304 159.157 109.691C169.911 95.4417 180.933 81.7311 191.957 67.7509C194.376 64.5254 197.065 61.5673 200.56 57.5353C196.796 55.9225 194.376 53.7708 193.033 54.3098C189.269 55.6531 184.967 57.8048 182.548 60.7629C172.869 72.0544 163.46 83.0785 155.125 94.9069L155.127 94.9048Z"
        fill="#51504D"
      />
      <path
        d="M129.316 67.7511C137.113 76.6234 132.542 84.9568 128.779 93.5616C128.243 94.9049 130.392 97.3262 131.738 100.282C134.426 98.1305 136.845 97.3241 138.191 95.4419C143.031 88.4518 146.794 80.6553 152.44 74.4717C163.732 62.1043 176.369 50.5433 188.197 38.4455C189.273 37.1022 189.54 33.3376 188.466 31.9923C187.123 30.649 183.089 29.3037 182.013 30.3795C172.335 38.4455 162.925 47.0483 153.247 55.3837C151.634 56.727 148.945 56.9965 146.794 58.0724C146.794 55.9206 145.987 53.232 146.794 51.6192C152.708 42.21 158.891 33.3376 164.808 23.9264C165.614 22.5831 165.077 18.5491 163.732 17.7427C162.388 16.6668 158.085 16.9363 157.279 18.2796C147.6 33.0661 138.458 48.3916 129.318 63.4476C128.512 64.254 128.512 66.9426 129.318 67.749L129.316 67.7511Z"
        fill="#51504D"
      />
      <path
        d="M172.333 218.578C170.989 219.922 171.526 222.88 171.257 225.031C174.482 225.031 178.784 226.644 180.399 225.031C185.506 220.461 189.271 214.816 193.842 209.706C205.672 196.263 217.231 182.822 229.062 169.646C235.245 162.656 242.505 156.472 248.689 149.751C250.301 148.139 250.571 145.181 251.377 143.298C250.571 141.955 250.034 140.61 249.764 140.61C247.613 140.61 244.657 140.34 243.044 141.686C215.084 163.195 195.187 192.231 172.335 218.578H172.333Z"
        fill="#51504D"
      />
      <path
        d="M165.073 195.456C163.46 197.339 164.267 201.371 163.997 204.598C166.955 204.061 170.987 204.868 172.6 203.255C193.84 179.057 214.81 154.324 235.78 129.857C236.856 128.781 236.856 126.632 237.393 125.286C236.856 124.749 236.586 123.404 236.05 123.404C233.091 123.673 228.79 123.135 227.177 124.747C206.207 147.869 185.504 171.528 165.073 195.456Z"
        fill="#51504D"
      />
      <path
        d="M208.359 108.348C191.42 127.436 174.752 146.526 158.083 165.884C156.47 167.766 155.394 170.185 154.857 172.337C154.588 173.413 156.47 174.756 157.815 176.638C159.967 176.101 162.923 176.101 164.536 174.486C169.376 169.646 173.678 164.538 178.247 159.43C190.614 145.45 202.981 131.47 215.618 117.757C219.113 113.993 223.145 110.767 226.373 107.003C227.716 105.39 227.986 101.626 226.91 100.282C225.834 98.9389 222.069 98.1305 220.457 99.2064C215.886 101.358 211.584 104.584 208.359 108.348Z"
        fill="#51504D"
      />
      <path
        d="M81.1922 164.269V165.881C82.8049 165.881 84.6872 166.418 86.3 165.881C88.4517 165.075 91.4078 163.999 91.9467 162.386C92.2162 160.773 90.334 158.085 88.7212 156.739C86.839 155.396 84.1503 154.32 81.7311 154.051C56.4595 151.632 31.1858 149.48 5.9142 147.598C4.03194 147.328 1.88226 149.749 0 150.823C1.3433 152.706 2.15172 155.664 3.7645 156.201C9.14179 158.083 15.056 160.232 20.7028 160.771C40.8666 162.115 61.0304 162.923 81.1942 164.266L81.1922 164.269Z"
        fill="#51504D"
      />
      <path
        d="M254.333 169.376C250.569 169.913 246.806 169.107 245.191 170.452C227.986 188.734 210.778 207.285 194.109 226.373C192.496 227.986 193.572 232.02 193.303 234.976C196.261 234.169 200.023 233.9 201.906 232.018C218.844 214.542 235.513 196.528 251.912 178.516C253.525 176.903 253.255 173.945 254.331 169.374L254.333 169.376Z"
        fill="#51504D"
      />
      <path
        d="M94.3659 225.836C95.4417 226.373 98.3978 223.953 99.4737 222.341C100.011 221.804 98.9367 219.115 98.1304 218.039C87.3758 208.091 73.6651 208.63 61.5673 208.091C42.4793 207.822 34.9503 209.434 24.4652 216.963C22.5829 218.307 21.7765 220.728 20.4332 222.61C23.1219 223.686 26.08 226.375 28.2297 225.836C50.2758 218.846 72.0524 214.814 94.3679 225.836H94.3659Z"
        fill="#51504D"
      />
      <path
        d="M24.1935 202.177C45.4331 197.876 66.6728 193.574 87.643 189.003C88.7189 188.734 89.5253 186.584 91.4075 183.357C88.182 181.474 84.9544 178.249 81.7288 178.249C60.4892 178.249 40.5948 184.702 21.5048 194.111C20.1615 194.918 19.6226 198.143 19.6226 200.295C19.892 200.832 22.8481 202.447 24.1935 202.177Z"
        fill="#51504D"
      />
      <path
        d="M10.7524 118.027C32.529 123.941 54.5751 129.857 76.6211 135.233C78.7729 135.769 81.192 134.157 83.6112 133.35C81.9984 130.931 80.9226 127.703 78.5034 126.091C73.1261 122.326 67.4813 119.37 61.8346 116.143C61.8346 115.873 61.5651 115.873 61.5651 115.606H61.2957L62.102 116.143C48.6588 114.26 35.4851 112.378 22.0418 110.228C18.8163 109.691 15.3213 108.346 12.0937 108.346C10.2114 108.346 6.98584 110.765 6.98584 111.841C7.25532 114.26 9.13759 117.488 10.7504 118.025L10.7524 118.027Z"
        fill="#51504D"
      />
      <path
        d="M245.461 226.375C243.309 225.299 240.621 223.686 238.741 224.223C216.964 232.02 202.714 248.151 190.078 266.433C189.271 267.509 191.153 270.465 191.96 272.617C194.379 272.347 196.531 272.617 197.607 271.81C213.469 258.1 229.062 244.387 244.924 230.676C245.731 229.87 246 226.644 245.461 226.375Z"
        fill="#51504D"
      />
      <path
        d="M227.179 272.347C219.652 277.455 212.93 283.908 206.209 290.361C205.672 290.898 208.091 296.815 209.704 297.082C212.662 297.888 216.157 296.006 218.307 295.469C224.221 296.276 229.868 298.695 233.9 297.082C237.932 295.739 242.772 290.629 243.309 286.866C243.846 281.759 241.427 275.575 238.469 271.273C237.393 269.93 229.866 270.467 227.177 272.349L227.179 272.347Z"
        fill="#51504D"
      />
      <path
        d="M64.2541 88.7211C65.5974 89.5275 67.4797 90.0644 69.0945 90.0644C70.7093 90.0644 72.5896 89.258 73.3959 88.1821C74.2023 87.1063 74.2023 84.4176 73.3959 83.6112C67.2123 77.9645 61.2981 71.7808 54.0385 67.4794C41.9406 59.9524 29.0343 53.7687 17.4754 47.3156C14.2498 49.1978 11.8286 50.2737 12.0981 50.5411C12.9045 52.6928 13.7109 55.6489 15.3237 56.7248C31.4555 67.7468 47.8548 78.2339 64.2541 88.719V88.7211Z"
        fill="#51504D"
      />
      <path
        d="M66.4034 102.164C54.036 93.5615 39.7864 87.9148 26.0758 81.4636C23.924 80.3878 20.968 80.1203 18.5488 80.6573C15.8601 81.1942 13.1715 83.0764 10.7523 84.1523C12.3651 86.0345 13.441 88.7232 15.3232 89.2601C20.7005 91.4118 26.6147 93.0246 31.992 94.9069C43.8224 98.9388 55.3814 103.779 61.5651 115.608C69.9005 113.456 74.7388 107.811 66.4054 102.164H66.4034Z"
        fill="#51504D"
      />
      <path
        d="M92.2143 250.034C95.4399 251.377 99.7413 249.764 103.775 249.497C101.087 246.002 98.9349 241.161 95.4399 239.548C91.1385 237.397 85.7612 237.666 76.6194 236.323C69.0924 239.012 58.0683 242.507 47.3138 246.539C45.4315 247.345 44.3556 250.034 43.0123 251.916C45.701 252.992 48.3896 254.874 50.5393 254.604C64.25 252.453 77.1563 244.389 92.2123 250.034H92.2143Z"
        fill="#51504D"
      />
      <path
        d="M74.7391 59.6849C75.276 60.2219 78.7711 59.148 78.7711 58.6091C79.308 56.4574 80.1144 52.9623 78.7711 51.8885C68.286 41.1339 57.2619 30.9183 46.2399 20.7006C45.164 19.8942 43.0143 20.1637 40.3257 19.6248C39.7888 21.507 37.9065 24.4651 38.7129 25.2715C50.5433 36.8325 62.6412 48.3935 74.7391 59.6849Z"
        fill="#51504D"
      />
      <path
        d="M113.454 279.07C114.53 279.07 116.143 277.188 116.68 275.844C116.949 274.501 116.41 271.543 115.604 271.273C98.1285 265.359 80.9208 266.166 63.9845 273.156C62.9087 273.693 62.1023 276.114 62.3718 277.457C62.6413 278.8 64.5235 280.683 65.3299 280.415C72.8569 279.878 80.3859 278.802 88.7193 277.727C96.5157 278.263 105.119 278.802 113.454 279.07Z"
        fill="#51504D"
      />
      <path
        d="M128.51 295.202C127.704 293.858 126.897 291.437 126.091 291.437C112.111 289.018 99.7435 293.856 87.6456 300.309C86.5698 300.846 85.7634 303.804 86.3023 304.341C87.6456 305.954 90.3343 308.373 91.6796 308.106C103.241 305.417 114.532 302.192 125.824 298.964C127.167 298.695 127.706 296.545 128.512 295.2L128.51 295.202Z"
        fill="#51504D"
      />
      <path
        d="M129.316 37.639C133.887 26.8844 137.919 16.1298 143.566 1.3433C142.49 1.07383 140.071 0 137.652 0C135.5 0 132.005 1.07587 131.468 2.41917C127.436 13.4412 123.941 24.4652 120.983 35.4873C120.446 37.9064 122.596 41.134 123.402 43.8227C125.015 41.9404 128.242 40.3276 129.316 37.639Z"
        fill="#51504D"
      />
      <path
        d="M123.939 313.214C119.907 318.591 106.464 319.667 111.302 327.733C115.603 334.723 124.745 327.196 131.196 323.968C134.422 322.355 136.573 318.591 140.069 314.826C136.843 312.944 134.961 310.794 133.078 310.794C129.853 310.794 125.552 311.331 123.937 313.214H123.939Z"
        fill="#51504D"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Particule',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
