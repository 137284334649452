<template>
  <svg class="particule" height="320" viewBox="0 0 247 391" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.1">
      <path
        d="M244.844 385.002C212.801 361.276 192.255 328.988 174.887 294.011C162.902 269.795 152.138 244.845 139.91 220.875C123.277 187.853 112.514 153.364 106.645 117.408C103.71 100.042 102.486 82.1853 100.285 64.5741C100.04 62.8616 98.5727 61.1491 97.5938 59.1934C95.6362 60.6607 93.9256 61.3943 93.1901 62.6183C88.2979 70.2018 88.0527 78.5171 90.0103 87.0776C92.7016 99.063 94.9026 111.294 97.8372 123.279C106.888 158.501 114.96 193.724 132.081 226.255C145.534 251.45 158.009 277.377 170.238 303.06C184.181 331.923 203.26 357.118 225.518 379.619C229.922 384.023 235.057 387.446 240.195 390.626C241.662 391.605 244.353 390.381 246.554 390.137C246.066 388.67 246.066 385.979 244.842 385L244.844 385.002Z"
        fill="white"
      />
      <path
        d="M141.133 86.3436C134.774 95.3944 124.989 104.2 133.55 117.408C134.529 118.875 133.061 121.811 132.571 123.767C131.104 128.414 127.19 136.731 127.924 137.22C135.262 141.623 137.708 133.795 141.867 129.881C144.802 127.19 147.004 124.01 149.694 121.321C162.903 108.112 176.111 95.1492 189.075 81.9398C192.01 78.7601 197.147 75.3351 192.01 70.6881C191.031 69.7092 185.405 72.4005 182.959 74.6015C171.952 84.3859 161.434 94.6589 150.671 104.443C149.204 105.665 146.757 105.665 144.8 106.156C144.8 103.955 143.821 101.264 144.8 99.7962C154.584 86.832 164.612 74.3582 174.642 61.6391C176.843 58.7045 179.289 56.0132 182.468 52.345C179.043 50.8777 176.843 48.9201 175.62 49.4104C172.196 50.6325 168.282 52.5902 166.081 55.2815C157.276 65.5544 148.715 75.584 141.132 86.3454L141.133 86.3436Z"
        fill="white"
      />
      <path
        d="M117.651 61.6398C124.744 69.7118 120.586 77.2934 117.163 85.1221C116.674 86.3442 118.63 88.547 119.854 91.2365C122.3 89.2788 124.501 88.5452 125.725 86.8327C130.129 80.4732 133.552 73.38 138.689 67.7541C148.962 56.5024 160.459 45.9843 171.221 34.9777C172.199 33.7556 172.443 30.3307 171.466 29.1067C170.244 27.8846 166.574 26.6606 165.595 27.6394C156.789 34.9777 148.229 42.8045 139.423 50.3881C137.956 51.6102 135.51 51.8553 133.552 52.8342C133.552 50.8765 132.818 48.4304 133.552 46.9631C138.933 38.4027 144.558 30.3307 149.941 21.7683C150.675 20.5462 150.186 16.8761 148.962 16.1425C147.74 15.1637 143.825 15.4088 143.091 16.631C134.286 30.0836 125.968 44.0267 117.653 57.7245C116.919 58.4582 116.919 60.9043 117.653 61.6379L117.651 61.6398Z"
        fill="white"
      />
      <path
        d="M156.787 198.862C155.565 200.084 156.054 202.775 155.808 204.733C158.743 204.733 162.656 206.2 164.126 204.733C168.773 200.574 172.198 195.439 176.356 190.79C187.119 178.559 197.636 166.33 208.399 154.343C214.025 147.984 220.629 142.358 226.255 136.243C227.723 134.776 227.968 132.085 228.701 130.372C227.968 129.15 227.479 127.926 227.234 127.926C225.277 127.926 222.587 127.681 221.12 128.905C195.682 148.474 177.58 174.891 156.789 198.862H156.787Z"
        fill="white"
      />
      <path
        d="M150.183 177.825C148.715 179.537 149.449 183.206 149.204 186.142C151.895 185.654 155.563 186.387 157.031 184.92C176.354 162.905 195.433 140.403 214.511 118.143C215.49 117.164 215.49 115.208 215.979 113.984C215.49 113.496 215.245 112.272 214.757 112.272C212.065 112.517 208.152 112.027 206.685 113.494C187.606 134.53 168.771 156.055 150.183 177.825Z"
        fill="white"
      />
      <path
        d="M189.563 98.5746C174.153 115.941 158.988 133.309 143.823 150.92C142.356 152.632 141.377 154.833 140.888 156.791C140.643 157.77 142.356 158.992 143.58 160.704C145.537 160.216 148.227 160.216 149.694 158.747C154.098 154.343 158.011 149.696 162.168 145.049C173.419 132.33 184.671 119.611 196.168 107.135C199.348 103.71 203.016 100.776 205.953 97.3507C207.175 95.8834 207.42 92.4584 206.441 91.2363C205.462 90.0142 202.037 89.2787 200.57 90.2575C196.411 92.2151 192.498 95.1497 189.563 98.5746Z"
        fill="white"
      />
      <path
        d="M73.8681 149.45V150.917C75.3354 150.917 77.0479 151.406 78.5152 150.917C80.4728 150.184 83.1623 149.205 83.6526 147.738C83.8978 146.27 82.1853 143.824 80.718 142.6C79.0056 141.378 76.5595 140.399 74.3585 140.154C51.3665 137.953 28.3727 135.996 5.3807 134.283C3.66824 134.038 1.71246 136.241 0 137.218C1.22213 138.93 1.95762 141.621 3.42492 142.11C8.31715 143.822 13.6979 145.778 18.8353 146.268C37.1802 147.491 55.5251 148.226 73.87 149.448L73.8681 149.45Z"
        fill="white"
      />
      <path
        d="M231.391 154.098C227.966 154.586 224.543 153.852 223.074 155.076C207.42 171.709 191.765 188.586 176.599 205.953C175.132 207.42 176.111 211.09 175.866 213.779C178.557 213.046 181.98 212.801 183.693 211.088C199.103 195.189 214.268 178.8 229.188 162.413C230.655 160.946 230.41 158.254 231.389 154.096L231.391 154.098Z"
        fill="white"
      />
      <path
        d="M85.8535 205.464C86.8323 205.952 89.5217 203.752 90.5006 202.284C90.989 201.796 90.0121 199.35 89.2784 198.371C79.494 189.32 67.0201 189.81 56.0135 189.32C38.6474 189.075 31.7976 190.542 22.2583 197.392C20.5458 198.614 19.8122 200.817 18.59 202.529C21.0361 203.508 23.7274 205.954 25.6832 205.464C45.7406 199.104 65.5528 195.436 85.8554 205.464H85.8535Z"
        fill="white"
      />
      <path
        d="M22.0111 183.94C41.3348 180.026 60.6586 176.113 79.7372 171.954C80.716 171.709 81.4496 169.753 83.1621 166.817C80.2275 165.105 77.291 162.17 74.3565 162.17C55.0327 162.17 36.9329 168.041 19.565 176.601C18.3429 177.335 17.8525 180.27 17.8525 182.227C18.0977 182.716 20.7871 184.185 22.0111 183.94Z"
        fill="white"
      />
      <path
        d="M9.78246 107.38C29.5947 112.761 49.6521 118.144 69.7095 123.034C71.6671 123.522 73.868 122.055 76.069 121.321C74.6017 119.12 73.6229 116.184 71.4219 114.717C66.5297 111.292 61.3941 108.602 56.2567 105.666C56.2567 105.421 56.0116 105.421 56.0116 105.177H55.7664L56.5001 105.666C44.2695 103.953 32.2841 102.241 20.0535 100.285C17.1189 99.7967 13.9392 98.5728 11.0027 98.5728C9.29028 98.5728 6.35568 100.774 6.35568 101.753C6.60085 103.953 8.31333 106.89 9.78062 107.378L9.78246 107.38Z"
        fill="white"
      />
      <path
        d="M223.319 205.954C221.361 204.976 218.915 203.508 217.205 203.997C197.393 211.09 184.428 225.767 172.931 242.399C172.198 243.378 173.91 246.067 174.644 248.025C176.845 247.78 178.802 248.025 179.781 247.291C194.213 234.817 208.399 222.342 222.831 209.868C223.564 209.134 223.809 206.2 223.319 205.954Z"
        fill="white"
      />
      <path
        d="M206.686 247.78C199.838 252.427 193.722 258.298 187.608 264.169C187.119 264.658 189.32 270.04 190.788 270.283C193.479 271.017 196.659 269.305 198.614 268.816C203.995 269.55 209.132 271.751 212.801 270.283C216.469 269.061 220.873 264.412 221.361 260.989C221.85 256.342 219.649 250.716 216.957 246.803C215.979 245.581 209.131 246.069 206.684 247.782L206.686 247.78Z"
        fill="white"
      />
      <path
        d="M58.458 80.7179C59.6801 81.4515 61.3926 81.94 62.8617 81.94C64.3309 81.94 66.0415 81.2064 66.7752 80.2275C67.5088 79.2487 67.5088 76.8026 66.7752 76.069C61.1493 70.9316 55.7686 65.3057 49.1639 61.3923C38.1573 54.5443 26.4152 48.9184 15.899 43.0474C12.9644 44.7598 10.7616 45.7386 11.0068 45.982C11.7404 47.9396 12.474 50.629 13.9413 51.6078C28.618 61.6356 43.538 71.1767 58.458 80.716V80.7179Z"
        fill="white"
      />
      <path
        d="M60.4134 92.9487C49.1616 85.1218 36.1974 79.9845 23.7235 74.1153C21.7659 73.1365 19.0765 72.8931 16.8755 73.3816C14.4294 73.8701 11.9833 75.5826 9.78235 76.5614C11.2496 78.2738 12.2285 80.72 13.9409 81.2084C18.8332 83.1661 24.2139 84.6334 29.1061 86.3458C39.8694 90.0141 50.3856 94.4178 56.0115 105.179C63.595 103.222 67.9969 98.0861 60.4152 92.9487H60.4134Z"
        fill="white"
      />
      <path
        d="M83.896 227.479C86.8306 228.701 90.744 227.234 94.4141 226.99C91.968 223.811 90.0104 219.407 86.8306 217.939C82.9172 215.982 78.025 216.227 69.7078 215.005C62.8598 217.451 52.8302 220.631 43.0458 224.299C41.3333 225.033 40.3545 227.479 39.1323 229.191C41.5785 230.17 44.0246 231.882 45.9804 231.637C58.4542 229.68 70.1963 222.343 83.8942 227.479H83.896Z"
        fill="white"
      />
      <path
        d="M67.9972 54.301C68.4857 54.7895 71.6654 53.8125 71.6654 53.3222C72.1539 51.3646 72.8876 48.1848 71.6654 47.2078C62.1262 37.4234 52.0965 28.1293 42.0688 18.8333C41.0899 18.0997 39.1342 18.3448 36.6881 17.8545C36.1996 19.567 34.4871 22.2582 35.2208 22.9919C45.984 33.51 56.9906 44.0281 67.9972 54.301Z"
        fill="white"
      />
      <path
        d="M103.22 253.896C104.199 253.896 105.666 252.183 106.154 250.961C106.4 249.739 105.909 247.048 105.176 246.803C89.2767 241.422 73.6212 242.155 58.2128 248.515C57.2339 249.004 56.5003 251.206 56.7455 252.428C56.9907 253.651 58.7031 255.363 59.4368 255.12C66.2848 254.631 73.1346 253.652 80.7163 252.674C87.8095 253.162 95.6363 253.652 103.22 253.896Z"
        fill="white"
      />
      <path
        d="M116.918 268.573C116.184 267.351 115.45 265.148 114.717 265.148C101.998 262.947 90.746 267.349 79.7394 273.22C78.7606 273.708 78.027 276.4 78.5173 276.888C79.7394 278.355 82.1856 280.556 83.4095 280.313C93.9276 277.867 104.201 274.932 114.474 271.996C115.696 271.751 116.186 269.795 116.92 268.571L116.918 268.573Z"
        fill="white"
      />
      <path
        d="M117.651 34.2437C121.81 24.4593 125.478 14.6748 130.615 1.22213C129.636 0.97696 127.435 0 125.235 0C123.277 0 120.097 0.978818 119.609 2.20095C115.94 12.2287 112.761 22.2583 110.069 32.2861C109.581 34.487 111.537 37.4235 112.27 39.8696C113.738 38.1571 116.674 36.6898 117.651 34.2437Z"
        fill="white"
      />
      <path
        d="M112.759 284.96C109.091 289.852 96.8599 290.831 101.262 298.169C105.175 304.529 113.492 297.681 119.362 294.744C122.296 293.277 124.254 289.852 127.434 286.427C124.499 284.715 122.786 282.759 121.074 282.759C118.139 282.759 114.226 283.247 112.757 284.96H112.759Z"
        fill="white"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Particule2',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
