<template>
  <svg class="particule" height="320" viewBox="0 0 247 391" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.1">
      <path
        d="M1.71066 385.002C33.7534 361.276 54.2993 328.988 71.6672 294.011C83.6526 269.795 94.4159 244.845 106.645 220.875C123.277 187.853 134.04 153.364 139.91 117.408C142.844 100.042 144.068 82.1853 146.269 64.5741C146.514 62.8616 147.982 61.1491 148.96 59.1934C150.918 60.6607 152.629 61.3943 153.364 62.6183C158.256 70.2018 158.502 78.5171 156.544 87.0776C153.853 99.063 151.652 111.294 148.717 123.279C139.666 158.501 131.594 193.724 114.473 226.255C101.021 251.45 88.5449 277.377 76.3162 303.06C62.3731 331.923 43.2946 357.118 21.0363 379.619C16.6325 384.023 11.497 387.446 6.35959 390.626C4.89229 391.605 2.201 390.381 6.10352e-05 390.137C0.488541 388.67 0.488541 385.979 1.71252 385L1.71066 385.002Z"
        fill="#51504D"
      />
      <path
        d="M105.421 86.3436C111.78 95.3944 121.565 104.2 113.004 117.408C112.026 118.875 113.493 121.811 113.983 123.767C115.45 128.414 119.364 136.731 118.63 137.22C111.292 141.623 108.846 133.795 104.687 129.881C101.753 127.19 99.5498 124.01 96.8604 121.321C83.651 108.112 70.4435 95.1492 57.4793 81.9398C54.5447 78.7601 49.4073 75.3351 54.5447 70.6881C55.5235 69.7092 61.1494 72.4005 63.5955 74.6015C74.6021 84.3859 85.1202 94.6589 95.8834 104.443C97.3507 105.665 99.7969 105.665 101.754 106.156C101.754 103.955 102.733 101.264 101.754 99.7962C91.97 86.832 81.9423 74.3582 71.9126 61.6391C69.7117 58.7045 67.2656 56.0132 64.0858 52.345C67.5108 50.8777 69.7117 48.9201 70.9338 49.4104C74.3587 50.6325 78.2722 52.5902 80.4731 55.2815C89.2787 65.5544 97.8392 75.584 105.423 86.3454L105.421 86.3436Z"
        fill="#51504D"
      />
      <path
        d="M128.903 61.6398C121.81 69.7118 125.968 77.2934 129.391 85.1221C129.88 86.3442 127.924 88.547 126.7 91.2365C124.254 89.2788 122.053 88.5452 120.829 86.8327C116.425 80.4732 113.002 73.38 107.865 67.7541C97.592 56.5024 86.0951 45.9843 75.3336 34.9777C74.3548 33.7556 74.1115 30.3307 75.0885 29.1067C76.3106 27.8846 79.9807 26.6606 80.9595 27.6394C89.7651 34.9777 98.3256 42.8045 107.131 50.3881C108.599 51.6102 111.045 51.8553 113.002 52.8342C113.002 50.8765 113.736 48.4304 113.002 46.9631C107.622 38.4027 101.996 30.3307 96.6131 21.7683C95.8795 20.5462 96.368 16.8761 97.5919 16.1425C98.8141 15.1637 102.729 15.4088 103.463 16.631C112.269 30.0836 120.586 44.0267 128.901 57.7245C129.635 58.4582 129.635 60.9043 128.901 61.6379L128.903 61.6398Z"
        fill="#51504D"
      />
      <path
        d="M89.767 198.862C90.9892 200.084 90.5007 202.775 90.7458 204.733C87.8113 204.733 83.8978 206.2 82.4287 204.733C77.7816 200.574 74.3567 195.439 70.1981 190.79C59.4348 178.559 48.9186 166.33 38.1553 154.343C32.5295 147.984 25.9248 142.358 20.2989 136.243C18.8316 134.776 18.5864 132.085 17.8528 130.372C18.5864 129.15 19.0749 127.926 19.3201 127.926C21.2777 127.926 23.9671 127.681 25.4344 128.905C50.8725 148.474 68.9741 174.891 89.7652 198.862H89.767Z"
        fill="#51504D"
      />
      <path
        d="M96.3716 177.825C97.8389 179.537 97.1053 183.206 97.3505 186.142C94.6592 185.654 90.991 186.387 89.5237 184.92C70.1999 162.905 51.1214 140.403 32.0428 118.143C31.064 117.164 31.064 115.208 30.5755 113.984C31.064 113.496 31.3091 112.272 31.7976 112.272C34.4889 112.517 38.4023 112.027 39.8696 113.494C58.9482 134.53 77.7834 156.055 96.3716 177.825Z"
        fill="#51504D"
      />
      <path
        d="M56.9908 98.5746C72.4012 115.941 87.5663 133.309 102.731 150.92C104.199 152.632 105.178 154.833 105.666 156.791C105.911 157.77 104.199 158.992 102.975 160.704C101.017 160.216 98.3277 160.216 96.8604 158.747C92.4567 154.343 88.5433 149.696 84.3866 145.049C73.1348 132.33 61.8831 119.611 50.3861 107.135C47.2064 103.71 43.5381 100.776 40.6017 97.3507C39.3796 95.8834 39.1344 92.4584 40.1132 91.2363C41.092 90.0142 44.517 89.2787 45.9843 90.2575C50.1428 92.2151 54.0562 95.1497 56.9908 98.5746Z"
        fill="#51504D"
      />
      <path
        d="M172.686 149.45V150.917C171.219 150.917 169.506 151.406 168.039 150.917C166.081 150.184 163.392 149.205 162.902 147.738C162.656 146.27 164.369 143.824 165.836 142.6C167.549 141.378 169.995 140.399 172.196 140.154C195.188 137.953 218.182 135.996 241.173 134.283C242.886 134.038 244.842 136.241 246.554 137.218C245.332 138.93 244.597 141.621 243.129 142.11C238.237 143.822 232.856 145.778 227.719 146.268C209.374 147.491 191.029 148.226 172.684 149.448L172.686 149.45Z"
        fill="#51504D"
      />
      <path
        d="M15.1634 154.098C18.5883 154.586 22.0114 153.852 23.4805 155.076C39.1342 171.709 54.7897 188.586 69.9548 205.953C71.4221 207.42 70.4433 211.09 70.6885 213.779C67.9972 213.046 64.5741 212.801 62.8617 211.088C47.4513 195.189 32.2862 178.8 17.3662 162.413C15.8989 160.946 16.144 158.254 15.1652 154.096L15.1634 154.098Z"
        fill="#51504D"
      />
      <path
        d="M160.701 205.464C159.722 205.952 157.032 203.752 156.054 202.284C155.565 201.796 156.542 199.35 157.276 198.371C167.06 189.32 179.534 189.81 190.541 189.32C207.907 189.075 214.757 190.542 224.296 197.392C226.008 198.614 226.742 200.817 227.964 202.529C225.518 203.508 222.827 205.954 220.871 205.464C200.814 199.104 181.001 195.436 160.699 205.464H160.701Z"
        fill="#51504D"
      />
      <path
        d="M224.543 183.94C205.219 180.026 185.896 176.113 166.817 171.954C165.838 171.709 165.105 169.753 163.392 166.817C166.327 165.105 169.263 162.17 172.198 162.17C191.521 162.17 209.621 168.041 226.989 176.601C228.211 177.335 228.702 180.27 228.702 182.227C228.456 182.716 225.767 184.185 224.543 183.94Z"
        fill="#51504D"
      />
      <path
        d="M236.772 107.38C216.959 112.761 196.902 118.144 176.845 123.034C174.887 123.522 172.686 122.055 170.485 121.321C171.952 119.12 172.931 116.184 175.132 114.717C180.024 111.292 185.16 108.602 190.297 105.666C190.297 105.421 190.543 105.421 190.543 105.177H190.788L190.054 105.666C202.285 103.953 214.27 102.241 226.501 100.285C229.435 99.7967 232.615 98.5728 235.551 98.5728C237.264 98.5728 240.198 100.774 240.198 101.753C239.953 103.953 238.241 106.89 236.774 107.378L236.772 107.38Z"
        fill="#51504D"
      />
      <path
        d="M23.2352 205.954C25.1928 204.976 27.6389 203.508 29.3495 203.997C49.1617 211.09 62.1259 225.767 73.6228 242.399C74.3565 243.378 72.644 246.067 71.9104 248.025C69.7094 247.78 67.7518 248.025 66.773 247.291C52.3415 234.817 38.1552 222.342 23.7236 209.868C22.99 209.134 22.7448 206.2 23.2352 205.954Z"
        fill="#51504D"
      />
      <path
        d="M39.8678 247.78C46.7158 252.427 52.832 258.298 58.9464 264.169C59.4349 264.658 57.2339 270.04 55.7667 270.283C53.0754 271.017 49.8956 269.305 47.9398 268.816C42.5591 269.55 37.4217 271.751 33.7535 270.283C30.0853 269.061 25.6815 264.412 25.193 260.989C24.7045 256.342 26.9055 250.716 29.5968 246.803C30.5756 245.581 37.4236 246.069 39.8697 247.782L39.8678 247.78Z"
        fill="#51504D"
      />
      <path
        d="M188.096 80.7179C186.874 81.4515 185.162 81.94 183.692 81.94C182.223 81.94 180.513 81.2064 179.779 80.2275C179.045 79.2487 179.045 76.8026 179.779 76.069C185.405 70.9316 190.786 65.3057 197.39 61.3923C208.397 54.5443 220.139 48.9184 230.655 43.0474C233.59 44.7598 235.793 45.7386 235.547 45.982C234.814 47.9396 234.08 50.629 232.613 51.6078C217.936 61.6356 203.016 71.1767 188.096 80.716V80.7179Z"
        fill="#51504D"
      />
      <path
        d="M186.141 92.9487C197.393 85.1218 210.357 79.9845 222.831 74.1153C224.788 73.1365 227.478 72.8931 229.679 73.3816C232.125 73.8701 234.571 75.5826 236.772 76.5614C235.305 78.2738 234.326 80.72 232.613 81.2084C227.721 83.1661 222.34 84.6334 217.448 86.3458C206.685 90.0141 196.169 94.4178 190.543 105.179C182.959 103.222 178.557 98.0861 186.139 92.9487H186.141Z"
        fill="#51504D"
      />
      <path
        d="M162.658 227.479C159.724 228.701 155.81 227.234 152.14 226.99C154.586 223.811 156.544 219.407 159.724 217.939C163.637 215.982 168.529 216.227 176.846 215.005C183.694 217.451 193.724 220.631 203.508 224.299C205.221 225.033 206.2 227.479 207.422 229.191C204.976 230.17 202.53 231.882 200.574 231.637C188.1 229.68 176.358 222.343 162.66 227.479H162.658Z"
        fill="#51504D"
      />
      <path
        d="M178.557 54.301C178.068 54.7895 174.889 53.8125 174.889 53.3222C174.4 51.3646 173.667 48.1848 174.889 47.2078C184.428 37.4234 194.458 28.1293 204.485 18.8333C205.464 18.0997 207.42 18.3448 209.866 17.8545C210.355 19.567 212.067 22.2582 211.333 22.9919C200.57 33.51 189.564 44.0281 178.557 54.301Z"
        fill="#51504D"
      />
      <path
        d="M143.334 253.896C142.356 253.896 140.888 252.183 140.4 250.961C140.155 249.739 140.645 247.048 141.379 246.803C157.277 241.422 172.933 242.155 188.341 248.515C189.32 249.004 190.054 251.206 189.809 252.428C189.564 253.651 187.851 255.363 187.117 255.12C180.269 254.631 173.42 253.652 165.838 252.674C158.745 253.162 150.918 253.652 143.334 253.896Z"
        fill="#51504D"
      />
      <path
        d="M129.636 268.573C130.37 267.351 131.104 265.148 131.837 265.148C144.556 262.947 155.808 267.349 166.815 273.22C167.794 273.708 168.527 276.4 168.037 276.888C166.815 278.355 164.369 280.556 163.145 280.313C152.627 277.867 142.354 274.932 132.081 271.996C130.859 271.751 130.368 269.795 129.635 268.571L129.636 268.573Z"
        fill="#51504D"
      />
      <path
        d="M128.903 34.2437C124.745 24.4593 121.076 14.6748 115.939 1.22213C116.918 0.97696 119.119 0 121.32 0C123.277 0 126.457 0.978818 126.946 2.20095C130.614 12.2287 133.794 22.2583 136.485 32.2861C136.973 34.487 135.018 37.4235 134.284 39.8696C132.817 38.1571 129.88 36.6898 128.903 34.2437Z"
        fill="#51504D"
      />
      <path
        d="M133.795 284.96C137.464 289.852 149.694 290.831 145.292 298.169C141.379 304.529 133.062 297.681 127.193 294.744C124.258 293.277 122.3 289.852 119.121 286.427C122.055 284.715 123.768 282.759 125.48 282.759C128.415 282.759 132.328 283.247 133.797 284.96H133.795Z"
        fill="#51504D"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Particule3',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
